.ctaBar {
  background: #f3f3f3;
  width: 100%;
  /* padding: 50px 20px; */
}

.ctaBar__container {
  /* padding-top: 0;
  padding-bottom: 0; */
}

.ctaBar__inner {
  align-items: center;
  justify-content: space-between;
}

.ctaBar__content {
  max-width: 68%;
}

.ctaBar__buttons {
  max-width: 32%;
}

@media only screen and (max-width: 1024px) {
  .ctaBar__content {
    max-width: 58%;
  }

  .ctaBar__buttons {
    max-width: 42%;
  }
}

@media only screen and (max-width: 768px) {
  /* .ctaBar {
    padding: 25px;
  } */

  /* .ctaBar__container {
    padding: 0;
  } */

  .ctaBar__inner {
    flex-direction: column;
  }

  .ctaBar__content {
    max-width: 100%;
    text-align: center;
  }

  .ctaBar__buttons {
    max-width: 100%;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 1025px) {
  .ctaBar__container {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
